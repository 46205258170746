import { useContext } from "react";

import { Button, Box, Typography } from "@mui/material";

import { Trans } from "../Translations";
import { PergContext } from "./PergState";

export default function Price(props: {}) {
  const { _ } = useContext(Trans);
  const { structure } = useContext(PergContext);
  const pRound = (priceToRound: number) => Math.round(priceToRound * 100) / 100;
  const pFormat = (priceToFormat: number) =>
    new Intl.NumberFormat("sl-SI", {
      style: "currency",
      currency: "EUR",
    }).format(priceToFormat);
  let price: number | undefined = 0;
  let emailBody = _("Hi\n\nI am interested in this model:\n\n");
  try {
    const widths = [
      200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
    ];
    const depths = [200, 250, 300, 350, 400, 450, 500];
    const widthDepthPriceTable = [
      [
        3138, 3335, 3630, 4120, 4435, 4790, 6430, 7190, 7850, 8195, 8470, 9790,
        10670,
      ],
      [
        3334, 3435, 3920, 4710, 4800, 5120, 6650, 7430, 8250, 8800, 9350, 10560,
        11550,
      ],
      [
        3530, 3920, 4259, 5090, 5459, 5790, 7000, 7800, 8500, 9000, 10230,
        11330, 12430,
      ],
      [
        3825, 4020, 4290, 5390, 5590, 5980, 7300, 8300, 9130, 9700, 10890,
        12100, 13200,
      ],
      [
        4120, 4410, 4690, 5690, 6080, 6490, 7630, 8900, 9790, 10560, 11770,
        13090, 13970,
      ],
      [
        4612, 4902, 5182, 6400, 6790, 7200, 10100, 11000, 11330, 12650, 13860,
        14905,
      ],
      [
        5104, 5394, 5674, 7110, 7500, 7910, 8720, 10900, 11800, 12300, 13420,
        14630, 15620,
      ],
    ];
    const montage = [
      950, 1050, 1200, 1500, 1750, 1950, 2800, 2800, 2800, 3190, 3190, 3190,
      3190,
    ];
    const montageFreestanding = [
      1450, 1550, 1750, 2100, 2500, 2890, 3900, 3900, 3900, 4500, 4500, 4500,
      4500,
    ];
    const roofGlassDepths = [400, 2000];
    const roofGlassPriceTable: {
      [index: string]: { [index: string]: number[] };
    } = {
      "afu-glass1": {
        "8mm": [0, 0],
        "10mm": [16, 17],
      },
      "afu-glass2": {
        "8mm": [28, 31],
        "10mm": [41, 47],
      },
    };
    // TODO(jakgra) roof awning depths more than 400cm price specification and remove 1000000 below
    const roofAwningDepths = [200, 250, 300, 350, 400, 1000000];
    // TODO(jakgra) roof awning widths more than 600cm price specification and remove 1000000 below
    const roofAwningWidths = [200, 300, 350, 400, 450, 500, 550, 600, 1000000];
    const roofAwningPriceTable = [
      // TODO(jakgra) remove all end values for 1000000 below and replace them with correct values
      [2890, 3140, 3230, 3380, 3465, 3550, 3615, 3765, 3765],
      [2970, 3180, 3290, 3400, 3640, 3615, 3750, 3950, 3950],
      [3040, 3340, 3430, 3550, 3770, 3770, 3890, 4130, 4130],
      [3140, 3390, 3500, 3650, 4050, 4150, 4190, 4490, 4490],
      [3290, 3480, 3640, 3750, 4150, 4250, 4450, 4680, 4680],
      [3290, 3480, 3640, 3750, 4150, 4250, 4450, 4680, 4680], // TODO remove this line for 1000000 above
    ];
    const awningWidths = [200, 300, 350, 400, 450, 500, 550, 600];
    // TODO(jakgra) is the height for awning price calculation always 250cm or do we need additional pricing logic???
    const awningPriceTable = [
      1890, 1950, 2090, 2180, 2315, 2630, 2830, 3050, 3330,
    ];
    const fixedWallPricePerM: { [index: string]: number } = {
      "afu-glass1": 799,
      "afu-glass2": 850,
    };
    const slidingWallPricePerM: { [index: string]: number } = {
      "afu-glass1": 750,
      "afu-glass2": 800,
    };
    const fixedWallMontageWidths = [11000, 1000000];
    const fixedWallMontagePriceTable = [350, 550];
    const slidingWallMontageWidths = [11000, 1000000];
    const slidingWallMontagePriceTable = [400, 600];
    /*
       const roofLength = Math.sqrt(
       Math.pow(structure.height_back - structure.height_front - 14, 2) +
       Math.pow(structure.depth - 14, 2)
       );
       */
    const roofLength = structure.depth; // TODO(jakgra) ask for this if this is real roof length or just depth without angle
    const widthIndex = widths.findIndex((it) => it >= structure.width);
    const depthIndex = depths.findIndex((it) => it >= structure.depth);
    const roofPrice = widthDepthPriceTable[depthIndex][widthIndex];
    const roofGlassIndex = roofGlassDepths.findIndex((it) => it >= roofLength);
    const roofGlassPriceM2 =
      roofGlassPriceTable[structure.roof_glass_material][
        structure.roof_glass_type
      ][roofGlassIndex];
    const roofGlassPrice = pRound(
      roofGlassPriceM2 * (roofLength / 100) * (structure.width / 100)
    );
    const montagePrice = (
      structure.freestanding ? montageFreestanding : montage
    )[widthIndex];
    const roofAwningPrice =
      structure.roof_awning_type === "none"
        ? 0
        : roofAwningPriceTable[
            roofAwningDepths.findIndex((it) => it >= roofLength)
          ][roofAwningWidths.findIndex((it) => it >= structure.width)];
    price += roofPrice;
    price += montagePrice;
    price += roofGlassPrice;
    price += roofAwningPrice;
    emailBody += "PRICE:\n";
    emailBody +=
      "-- Überdachung „Luxline“ [8mm VSG klar]: " + pFormat(roofPrice) + "\n";
    emailBody +=
      "-- montage (" +
      (structure.freestanding ? "Freestanding" : "Wall-Mounted") +
      "): " +
      pFormat(montagePrice) +
      "\n";
    emailBody += "-- roof glass addition: " + pFormat(roofGlassPrice) + "\n";
    emailBody += "-- roof awning addition: " + pFormat(roofAwningPrice) + "\n";
    let wholeSlidingWallLength = 0;
    let wholeFixedWallLength = 0;
    for (const side of structure.side_panels) {
      const sideWidth =
        (side.position === "left" || side.position === "right"
          ? structure.depth
          : structure.width) /
        structure.side_panels.filter((it) => it.position === side.position)
          .length;
      emailBody +=
        "------------------ side: " +
        side.position +
        " " +
        (side.sub_pos + 1).toString() +
        " (" +
        (Math.round(sideWidth * 100) / 100).toString() +
        "cm) ------------------\n";
      const awningPrice =
        side.awning_type === "none"
          ? 0
          : awningPriceTable[awningWidths.findIndex((it) => it >= sideWidth)];
      price += awningPrice;
      emailBody += "****************** awning: " + pFormat(awningPrice) + "\n";

      let wallPrice = 0;
      if (side.type === "fixed") {
        wallPrice = pRound(
          (sideWidth / 100) * fixedWallPricePerM[side.glass_material]
        );
        wholeFixedWallLength += sideWidth;
      } else if (side.type === "sliding") {
        wallPrice = pRound(
          (sideWidth / 100) * slidingWallPricePerM[side.glass_material]
        );
        wholeSlidingWallLength += sideWidth;
      }
      price += wallPrice;
      emailBody +=
        "****************** wall (" +
        side.type +
        "): " +
        pFormat(wallPrice) +
        "\n";
    }
    const fixedWallMontagePrice = pRound(
      (wholeFixedWallLength / 100) *
        fixedWallMontagePriceTable[
          fixedWallMontageWidths.findIndex((it) => it >= wholeFixedWallLength)
        ]
    );
    price += fixedWallMontagePrice;
    emailBody +=
      "-- fixed wall montage: " + pFormat(fixedWallMontagePrice) + "\n";
    const slidingWallMontagePrice = pRound(
      (wholeSlidingWallLength / 100) *
        slidingWallMontagePriceTable[
          slidingWallMontageWidths.findIndex(
            (it) => it >= wholeSlidingWallLength
          )
        ]
    );
    price += slidingWallMontagePrice;
    emailBody +=
      "-- sliding wall montage: " + pFormat(slidingWallMontagePrice) + "\n";
    emailBody += "-- total: " + pFormat(price) + "\n";
  } catch (err) {
    price = undefined;
    console.error(err);
  }
  return (
    <Box
      sx={{
        boxShadow: 5,
        position: "fixed",
        bottom: 0,
        right: 0,
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "@media (orientation: landscape)": {
          width: {
            xs: "250px",
            lg: "350px",
            xl: "500px",
          },
          height: {
            xs: "50px",
            xl: "95px",
          },
        },
        "@media (orientation: portrait)": {
          width: "100%",
          height: "50px",
        },
      }}
    >
      <Typography
        sx={{
          marginLeft: "15px",
          fontWeight: 600,
          fontFamily: '"Montserrat", sans-serif',
          "@media (orientation: landscape)": {
            fontSize: {
              xs: "1em",
              lg: "1.4em",
              xl: "2em",
            },
          },
          "@media (orientation: portrait)": {
            fontSize: "1.5em",
          },
        }}
      >
        {
          /*_("Request quote:") */
          price &&
            new Intl.NumberFormat("sl-SI", {
              style: "currency",
              currency: "EUR",
            }).format(price)
        }
      </Typography>
      <Button
        href={
          "mailto:kontakt@af-ueberdachung.de?body=" +
          encodeURIComponent(emailBody)
        }
        // onClick={async () => alert("Coming soon")}
        sx={{
          ":hover": {
            backgroundColor: "#000",
          },
          margin: "0 15px",
          color: "white",
          backgroundColor: "#04dceb",
          fontWeight: "bold",
          border: "none",
          borderRadius: "7px",
          textTransform: "uppercase",
          transition: "all 0.3s",
          fontFamily: '"Montserrat", sans-serif',
          "@media (orientation: landscape)": {
            xs: {
              height: "40px",
              fontSize: "10px",
            },
            xl: {
              height: "60px",
              padding: "20px",
              fontSize: "14px",
            },
          },
          "@media (orientation: portrait)": {
            height: "40px",
            fontSize: "10px",
          },
        }}
      >
        {_("Inquire")}
      </Button>
    </Box>
  );
}
